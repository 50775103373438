import { PageProps } from "gatsby";
import React from "react";

import { Learn } from "../../components/pages/Learn";

import { dnProps } from "../../js/utils";

const LearnPage = (props: PageProps) => <Learn {...{ ...dnProps, ...props }} />;

export default LearnPage;
